import React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard"
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight"
import { faCcVisa } from "@fortawesome/free-brands-svg-icons/faCcVisa"
import { faCcDiscover } from "@fortawesome/free-brands-svg-icons/faCcDiscover"
import { faCcMastercard } from "@fortawesome/free-brands-svg-icons/faCcMastercard"
import { faCcAmex } from "@fortawesome/free-brands-svg-icons/faCcAmex"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Button from "@components/Button/Button"

const MakeAPayment = ({ data, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const language = "en"

  return (
    <Layout language={language}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
      />
      <div className="columns make-a-payment">
        <div className="column is-4"></div>
        <div className="column">
          <h1 className="has-text-centered">{post.heading}</h1>
          <Button href={post.payLink} buttonText="PAY NOW" contained external />

          <h2 className="has-text-centered">IMPORTANT!</h2>
          <h2 className="has-text-centered">
            YOUR PATIENT ID NUMBER WILL BE YOUR INVOICE NUMBER ON THE NEXT PAGE
          </h2>
          <h2 className="has-text-centered">
            MAX Surgical Specialty Management processes payments for Oral
            Surgery Group
          </h2>

          <div className="make-a-payment__credit">
            <FontAwesomeIcon icon={faCcVisa} />
            <FontAwesomeIcon icon={faCcMastercard} />
            <FontAwesomeIcon icon={faCcDiscover} />
            <FontAwesomeIcon icon={faCcAmex} />
          </div>

          <p className="has-text-centered">You can pay using:</p>

          <div className="make-a-payment__options">
            <div className="make-a-payment__option">
              <FontAwesomeIcon icon={faCreditCard} />
              <h3>Credit Card</h3>
              <p>3% Fee</p>
            </div>
            <div className="make-a-payment__option">
              <FontAwesomeIcon icon={faDollarSign} />
              <h3>Debit Card</h3>
              <p>Free</p>
            </div>
            <div className="make-a-payment__option">
              <FontAwesomeIcon icon={faArrowRight} />
              <h3>Bank Transfer</h3>
              <p>Free</p>
            </div>
          </div>
        </div>
        <div className="column is-4"></div>
      </div>
    </Layout>
  )
}

export const makeAPaymentQuery = graphql`
  query MyQuery {
    allUniquePagesJson(filter: { title: { eq: "make-a-payment/" } }) {
      nodes {
        heading
        metaDescription
        metaTitle
        payLink
      }
    }
  }
`

export default MakeAPayment
